<template>
  <SlModal
    :id="id"
    :width="800"
    :title="$t('Main.menuImportSpreadsheet')"
    :subtitle="$t('Web.Spreadsheet.ModalConnectionSubtitle')"
    :close-callback="handleCancel"
    persistent
    @on-enter="handleConfirm"
    @created="onCreated"
    @show="onModalShow"
    @hidden="onModalHide"
  >
    <div class="modal-content spreadsheet-connector">
      <div class="spreadsheet-connector__type-picker">
        <SlRadioPanel
          v-model="dataModel"
          :label="$t('Main.Ui.acOrderListImport')"
          :description="$t('Web.Spreadsheet.RadioDescriptionTransactional')"
          :selected="connectionTypes.SPREADSHEET_TRANSACTIONAL"
        >
          <template #footer>
            <SlAccordion
              variant="light"
              inline
            >
              <template #trigger="{ isOpen, toggle }">
                <SlButton
                  variant="text"
                  color="grey"
                  @click.stop="toggle"
                >
                  <template #prepend>
                    <icon
                      data="@icons/triangle_down.svg"
                      class="fill-off size-16"
                      :class="{
                        'rotate--90': !isOpen
                      }"
                    />
                  </template>
                  {{ $t('Web.Spreadsheet.BtnMoreInfo') }}
                </SlButton>
              </template>
              <div class="more-info-links">
                <SlLink
                  :href="SPREADSHEET_TRANSACTIONAL_TUTORIAL_URL"
                  target="_blank"
                >
                  <template #prepend>
                    <icon
                      data="@icons/open_link.svg"
                      class="fill-off size-16"
                    />
                  </template>
                  {{ $t('Web.Spreadsheet.BtnInstruction') }}
                </SlLink>
                <SlLink
                  :href="SPREADSHEET_TRANSACTIONAL_TEMPLATE_URL"
                  target="_blank"
                >
                  <template #prepend>
                    <icon
                      data="@icons/download.svg"
                      class="fill-off size-16"
                    />
                  </template>
                  {{ $t('Web.Spreadsheet.BtnDataTemplate') }}
                </SlLink>
              </div>
            </SlAccordion>
          </template>
        </SlRadioPanel>
        <SlRadioPanel
          v-model="dataModel"
          :label="$t('Main.Ui.acSheetImport')"
          :description="$t('Web.Spreadsheet.RadioDescriptionAggregated')"
          :selected="connectionTypes.SPREADSHEET_AGGREGATED"
        >
          <template #footer>
            <SlAccordion
              variant="light"
              inline
            >
              <template #trigger="{ isOpen, toggle }">
                <SlButton
                  variant="text"
                  color="grey"
                  @click.stop="toggle"
                >
                  <template #prepend>
                    <icon
                      data="@icons/triangle_down.svg"
                      class="fill-off size-16"
                      :class="{
                        'rotate--90': !isOpen
                      }"
                    />
                  </template>
                  {{ $t('Web.Spreadsheet.BtnMoreInfo') }}
                </SlButton>
              </template>
              <div class="more-info-links">
                <SlLink
                  :href="SPREADSHEET_AGGREGATED_TUTORIAL_URL"
                  target="_blank"
                >
                  <template #prepend>
                    <icon
                      data="@icons/open_link.svg"
                      class="fill-off size-16"
                    />
                  </template>
                  {{ $t('Web.Spreadsheet.BtnInstruction') }}
                </SlLink>
                <SlLink
                  :href="SPREADSHEET_AGGREGATED_TEMPLATE_URL"
                  target="_blank"
                >
                  <template #prepend>
                    <icon
                      data="@icons/download.svg"
                      class="fill-off size-16"
                    />
                  </template>
                  {{ $t('Web.Spreadsheet.BtnDataTemplate') }}
                </SlLink>
              </div>
            </SlAccordion>
          </template>
        </SlRadioPanel>
      </div>
      <div class="spreadsheet-connector__upload-label">
        <div class="spreadsheet-connector__upload-label-title">
          {{ dropzoneLabel }}
        </div>
        <div class="spreadsheet-connector__upload-label-description">
          {{ $t('Web.Spreadsheet.DescriptionMaxFiles', { 1: MAX_FILES }) }}
        </div>
      </div>

      <FilesUpload
        ref="fileUpload"
        :link-alert="noUploadedLinks"
        @link-upload-success="noUploadedLinks = false"
        @loading="toggleUploading"
      />
    </div>

    <template #footer>
      <SlModalFooter between>
        <div class="sl-modal__footer-group">
          <SlButton
            variant="secondary"
            color="grey"
            @click="handleCancel"
          >
            {{ $t('Common.Cancel') }}
          </SlButton>
          <SlButton
            :disabled="isUploading"
            @click="handleConfirm"
          >
            {{ $t('Web.Modals.BtnConfirm') }}
          </SlButton>
        </div>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FilesUpload from '@/components/Connections/Spreadsheet/FilesUpload';
import { modal } from '@/mixins/modal';
import { fileSaver } from '@/mixins/webAPI';
import { spreadsheet } from '@/mixins/connection/spreadsheet.mixin';
import modalsId from '@/config/shared/modalsId.config';
import {
  SPREADSHEET_AGGREGATED_TEMPLATE_URL,
  SPREADSHEET_AGGREGATED_TUTORIAL_URL,
  SPREADSHEET_TRANSACTIONAL_TEMPLATE_URL,
  SPREADSHEET_TRANSACTIONAL_TUTORIAL_URL
} from '@/config/shared/resources.config';
import { connectorRouteNames } from '@/config/router/router.config';
import { connectionTypes } from '@/config/connection';
import { MAX_FILES } from '@/config/connection/spreadsheet.config';

export default {
  name: 'SpreadsheetConnectorModal',
  components: { FilesUpload },
  mixins: [modal, fileSaver, spreadsheet],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      MAX_FILES,
      modalsId,
      connectionTypes,
      SPREADSHEET_TRANSACTIONAL_TUTORIAL_URL,
      SPREADSHEET_AGGREGATED_TUTORIAL_URL,
      SPREADSHEET_TRANSACTIONAL_TEMPLATE_URL,
      SPREADSHEET_AGGREGATED_TEMPLATE_URL,
      isNew: false,
      isUploading: false
    };
  },
  computed: {
    ...mapState({
      projectId: (state) => state.manageProjects.projectId,
      commonData: state => state.spreadsheet.commonData,
      filesSource: state => state.spreadsheet.files_source,
      selectedConnector: state => state.connection.selectedConnector
    }),
    dropzoneLabel() {
      return this.dataModel === connectionTypes.SPREADSHEET_TRANSACTIONAL
        ? this.$t('Web.Spreadsheet.UploadWithLabel', {
          1: this.$t('Main.Ui.acOrderListImport')
        })
        : this.$t('Web.Spreadsheet.UploadWithLabel', {
          1: this.$t('Main.Ui.acSheetImport')
        });
    },
    dataModel: {
      get() {
        return this.commonData.type;
      },
      set(value) {
        this.updateTabSettings({
          tab: 'commonData',
          key: 'type',
          value
        });
        this.selectConnector(value);
      }
    }
  },
  methods: {
    ...mapActions({
      setFilesSource: 'spreadsheet/setFilesSource',
      setFiles: 'spreadsheet/setFiles',
      updateTabSettings: 'spreadsheet/updateTabSettings',
      resetState: 'spreadsheet/resetState',
      selectConnector: 'connection/selectConnector'
    }),
    onModalShow() {
      if (this.isNew) {
        this.resetState();
      }

      this.updateTabSettings({
        tab: 'commonData',
        key: 'type',
        value: this.selectedConnector
      });
    },
    onModalHide() {
      this.isNew = false;
      this.isUploading = false;
    },
    handleCancel() {
      this.hideModal(this.id);
    },
    toggleUploading(value) {
      this.isUploading = value;
    },
    async handleConfirm() {
      const valid = await this.validateUploads();

      if (!valid) {
        return;
      }

      const { fileSource, files } = await this.getFilesData();

      this.setFilesSource(fileSource);
      this.setFiles(files);

      await this.$router.push({
        name: this.isNew
          ? connectorRouteNames.CREATE_PROJECT_CONNECTOR
          : connectorRouteNames.EDIT_CONNECTION_CONNECTOR,
        params: {
          connector: connectionTypes.SPREADSHEET,
          ...(this.isNew ? {} : { projectId: this.projectId })
        }
      });

      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/connection/connectors/spreadsheet/spreadsheet-connector-modal.scss";
</style>
