<template>
  <SidebarDropdown v-bind="$attrs">
    <template
      v-for="(_, slot) in $scopedSlots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope || {}"
      />
    </template>
    <template #content>
      <div class="dropdown-menu">
        <div
          v-if="$sl_hasProjectAccess"
          class="dropdown-menu__block dropdown-menu__block--info"
        >
          <div class="dropdown-menu__title">
            {{ projectLabel }}
          </div>
        </div>
        <div
          v-if="$sl_hasProjectAccess && !isProjectSettingsDisabled"
          class="dropdown-menu__block"
        >
          <SidebarDropdownButton
            :label="$t('Main.Ui.acSettings')"
            icon="settings"
            @click="openProjectSettings"
          />
        </div>
        <div
          v-if="$sl_hasAccess($sl_features.manageUsers)"
          class="dropdown-menu__block"
        >
          <SidebarDropdownButton
            :label="$t('Main.tabNames.users')"
            icon="users"
            @click="openUsers"
          >
            <template
              v-if="isFreePlan"
              #append-inner
            >
              <icon
                data="@icons/upgrade-plan.svg"
                class="fill-off size-16"
              />
            </template>
          </SidebarDropdownButton>
        </div>
        <div
          v-if="isProjectBlockVisible"
          class="dropdown-menu__block"
        >
          <SidebarDropdownButton
            :label="$t('Main.Ui.acEditConnectionSettings')"
            icon="edit_square"
            :disabled="isEditConnectionDisabled"
            @click="openEditConnection"
          />
        </div>
        <div
          v-if="isItemBlockVisible && $sl_hasProjectAccess"
          class="dropdown-menu__block"
        >
          <SidebarDropdownButton
            v-if="$sl_hasAccess($sl_features.remSubsRules)"
            :label="$t('Main.Ui.acSubstitutions')"
            icon="rem_sub_rules"
            @click="openRemovalSubstitutionRules"
          />
          <SidebarDropdownButton
            v-if="hasBatchesOnHand"
            :label="$t('Main.Ui.acExpirations')"
            icon="batches_on_hand"
            @click="openBatchesOnHand"
          />
          <SidebarDropdownButton
            v-if="hasBomAccess"
            :label="$t('Main.Ui.menuBom_2')"
            icon="bom"
            @click="handleOpenBoms"
          />
          <SidebarDropdownButton
            v-if="hasSuppliersAccess"
            :label="$t('Web.Suppliers.PageTitle')"
            icon="suppliers"
            @click="handleOpenSuppliers"
          />
          <SidebarDropdownButton
            v-if="$sl_hasRouteAccess($sl_routes.DEMAND)"
            :label="$t('ViewPromo.Ui.:Caption')"
            icon="promotions"
            @click="handleOpenPromotions"
          />
        </div>
        <div class="dropdown-menu__block">
          <SidebarDropdownButton
            :label="$t('Main.Ui.acProjectStats')"
            icon="info"
            @click="showModal(modalsId.PROJECT_STATS)"
          />
        </div>
        <div
          v-if="!isTimeMachineRunning && projectId"
          class="dropdown-menu__block"
        >
          <SidebarDropdownButton
            :label="$t('Main.TimeMachine.Start')"
            icon="time-machine"
            class="stroke-icon--grey"
            @click="startTimeMachine"
          />
        </div>
      </div>
    </template>
  </SidebarDropdown>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import { fileSaver } from '@/mixins/webAPI';
import SidebarDropdown from '@/components/SidebarMenu/Dropdown/SidebarDropdown.vue';
import SidebarDropdownButton from '@/components/SidebarMenu/Dropdown/SidebarDropdownButton.vue';
import { bomTypes } from '@/config/item/bom.config';
import modalsId from '@/config/shared/modalsId.config';
import { connectorRouteNames, routeNames } from '@/config/router/router.config';
import {
  connectionsConfig,
  connectionTypes,
  connectorByType,
  dbRelatedConnections,
  spreadsheetTypes
} from '@/config/connection';
import { connection } from '@/mixins/connection';
import { toggleUserInteraction } from '@/helpers/shared/webAPI';
import { projectRedirect } from '@/helpers/router';

export default {
  name: 'ProjectDropdown',
  components: {
    SidebarDropdown,
    SidebarDropdownButton
  },
  mixins: [modal, access, connection, fileSaver],
  data() {
    return {
      modalsId,
      bomTypes,
      routeNames,
      connectorRouteNames,
      restrictedEditConnectionRouteNames: [
        connectorRouteNames.EDIT_CONNECTION,
        connectorRouteNames.EDIT_CONNECTION_CONNECTOR,
        connectorRouteNames.CREATE_PROJECT_CONNECTOR
      ],
      connectorsByType: connectionsConfig(this)
    };
  },
  computed: {
    ...mapState({
      projectId: (state) => state.manageProjects.projectId,
      selectedConnector: state => state.connection.selectedConnector
    }),
    ...mapGetters({
      isTimeMachineRunning: 'manageProjects/isTimeMachineRunning',
      hasBatchesOnHand: 'project/hasBatchesOnHand',
      hasBom: 'project/hasBom',
      canEditConnection: 'project/canEditConnection',
      isMultipleConnections: 'project/isMultipleConnections',
      projectLabel: 'project/projectLabel',
      isFreePlan: 'initialization/isFreePlan'
    }),
    hasBomAccess() {
      return this.hasBom && this.$sl_hasAccess(this.$sl_features.bomAccess);
    },
    hasSuppliersAccess() {
      return this.$sl_hasAccess(this.$sl_features.accessSuppliers);
    },
    isProjectSettingsDisabled() {
      return !this.$sl_hasAccess(this.$sl_features.projectSettings);
    },
    isProjectBlockVisible() {
      return this.$sl_hasAccess(this.$sl_features.editConnection) && this.$sl_hasProjectAccess;
    },
    isItemBlockVisible() {
      return this.$sl_hasAccess(this.$sl_features.remSubsRules)
        || this.hasBatchesOnHand
        || this.hasBomAccess
        || this.hasSuppliersAccess;
    },
    isEditConnectionDisabled() {
      return this.restrictedEditConnectionRouteNames.includes(this.$sl_routeName);
    },
    connectorsData() {
      return connectorByType(this);
    },
    testConnectionAction() {
      const actionByConnector = {
        [connectionTypes.NETSUITE]: this.testNetsuiteConnection
      };

      return actionByConnector[this.selectedConnector] || this.testDbRelatedConnection;
    }
  },
  methods: {
    ...mapActions({
      getConnectionInfo: 'connection/getConnectionInfo',
      getSpireDatabases: 'spire/getSpireDatabases',
      checkQbConnection: 'qb/checkQbConnection',
      startTimeMachine: 'timeMachine/start',
      testDbRelatedConnection: 'dbRelated/testConnection',
      testNetsuiteConnection: 'netsuite/testConnection',
      setBomEntry: 'bom/setSelectedEntry'
    }),
    async editDbRelatedConnection(connector) {
      try {
        await this.testConnectionAction({
          isEdit: true
        });

        return true;
      } catch (e) {
        await projectRedirect({
          name: connectorRouteNames.EDIT_CONNECTION
        });

        setTimeout(async() => {
          await this.showModal(connector.modal, { isNew: true });

          setTimeout(() => {
            this.showModal(modalsId.CONNECTOR_ERROR, {
              connector: this.$t('Web.Modals.Error.TextConnectToDatasource'),
              error: e?.message
            });
          }, 100);
        }, 300);
      }
    },
    async openEditConnection() {
      if (this.isMultipleConnections) {
        return projectRedirect({
          name: routeNames.INTEGRATIONS
        });
      }

      if (!this.canEditConnection) {
        return this.showModal(modalsId.SL_ERROR_MODAL, {
          icon: 'style_warning_double',
          title: this.$t('Web.Error.DummyEditConnectionErrorTitle'),
          text: this.$t('Web.Error.DummyEditConnectionErrorText')
        });
      }

      try {
        toggleUserInteraction(true);

        await this.getConnectionInfo();

        const connector = this.connectorsData[this.selectedConnector];

        if (!connector) {
          return;
        }

        if (spreadsheetTypes.includes(this.selectedConnector)) {
          return projectRedirect({
            name: connectorRouteNames.EDIT_CONNECTION_CONNECTOR,
            params: {
              connector: connectionTypes.SPREADSHEET
            }
          });
        }

        if (dbRelatedConnections.includes(this.selectedConnector)) {
          this.showModal(modalsId.DATASOURCE_CONNECTION, {
            source: this.connectorsByType[this.selectedConnector].title
          });

          const connected =  await this.editDbRelatedConnection(connector);

          if (connected) {
            return projectRedirect({
              name: connectorRouteNames.EDIT_CONNECTION_CONNECTOR,
              params: {
                connector: this.selectedConnector
              }
            });
          }

          return;
        }

        if (this.selectedConnector === connectionTypes.SPIRE) {
          const connected = await this.getSpireDatabases({ silent: true });

          if (!connected) {
            await projectRedirect({
              name: connectorRouteNames.EDIT_CONNECTION
            });

            return setTimeout(() => {
              this.showModal(modalsId.SPIRE_CONNECTOR, { isNew: true });
            }, 300);
          }
        }

        if (this.selectedConnector === connectionTypes.QB_DESKTOP && !this.isNew) {
          await this.checkQbConnection({
            beforeStart: () => {
              this.showModal(modalsId.QB_DESKTOP_LOADER);
            },
            failed: async(text, title) =>  {
              await projectRedirect({
                name: connectorRouteNames.EDIT_CONNECTION
              });

              this.showModal(modalsId.QB_DESKTOP_CONNECTOR);

              setTimeout(() => {
                this.showModal(modalsId.QB_DESKTOP_ERROR, { text, title });
              }, 100);
            },
            finished: () => {
              this.$router.push({
                name: connectorRouteNames.EDIT_CONNECTION_CONNECTOR,
                params: {
                  connector: this.selectedConnector
                }
              });
            },
            finally: () => {
              this.hideModal(modalsId.QB_DESKTOP_LOADER);
            }
          });

          return;
        }

        this.$router.push({
          name: connectorRouteNames.EDIT_CONNECTION_CONNECTOR,
          params: {
            connector: this.selectedConnector
          }
        });
      } finally {
        setTimeout(() => {
          this.hideModal(modalsId.DATASOURCE_CONNECTION);
        }, 500);
        toggleUserInteraction(false);
      }
    },
    handleOpenBoms() {
      this.setBomEntry({
        route: routeNames.INVENTORY
      });

      this.$router.push({
        name: routeNames.BILL_OF_MATERIALS,
        params: {
          type: bomTypes.ENTIRE
        }
      });
    },
    handleOpenSuppliers() {
      projectRedirect({
        name: routeNames.SUPPLIERS
      });
    },
    handleOpenPromotions() {
      this.$router.push({
        name: routeNames.PROMOTIONS
      });
    },
    openRemovalSubstitutionRules() {
      projectRedirect({
        name: routeNames.REMOVAL_SUBSTITUTION_RULES
      });
    },
    openBatchesOnHand() {
      projectRedirect({
        name: routeNames.BATCHES_ON_HAND
      });
    },
    openProjectSettings() {
      this.$router.push({
        name: routeNames.SETTINGS,
        hash: `#${this.$sl_routeName}`
      });
    },
    openUsers() {
      if (this.isFreePlan) {
        return this.showModal(modalsId.UPGRADE_PLAN);
      }

      projectRedirect({
        name: routeNames.USERS
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/sidebar-menu/dropdown-menu.scss";
</style>
