var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spreadsheet__files-upload"},[_c('SlControl',{attrs:{"options":_vm.fileSourceOptions},model:{value:(_vm.filesSourceModel),callback:function ($$v) {_vm.filesSourceModel=$$v},expression:"filesSourceModel"}}),_c('ValidationObserver',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSourceFile),expression:"isSourceFile"}],ref:"dropzoneObserver"},[_c('SlValidate',{attrs:{"vid":"file","rules":{
        required: true,
        sl_file_type: ['.xlsx', '.xls', '.csv'],
        sl_file_size: true,
        sl_file_duplicate: _vm.uploadedFileNames
      },"message":!_vm.files.length},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
      var validate = ref.validate;
return [_c('SlDropzone',{ref:"dropzone",attrs:{"accepted":_vm.spreadsheetAccepted,"max-files":_vm.remainFiles,"tooltip-max-files":_vm.MAX_FILES,"upload-callback":_vm.uploadFile,"validator":validate,"is-invalid":invalid},on:{"queue-upload-start":function($event){return _vm.emitUploading(true)},"queue-upload-end":function($event){return _vm.emitUploading(false)},"upload-success":_vm.handleFileUploaded,"file-removed":_vm.handleRemoveFile},scopedSlots:_vm._u([{key:"accepted",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.$t('Web.FileUpload.SupportedFormat', { 1: _vm.$t('Web.Spreadsheet.UploadAccepted') }))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('Web.FileUpload.MaxFileSize', { 1: '500MB' }))+" ")])]},proxy:true}],null,true)})]}}])})],1),_c('ValidationObserver',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isSourceFile),expression:"!isSourceFile"}],ref:"linksObserver",staticClass:"spreadsheet__upload-link"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.linkAlert)?_c('SlAlert',{attrs:{"type":"error","accent":_vm.$t('Web.Spreadsheet.AlertNoUploadedLinks')}}):_vm._e()],1),_vm._l((_vm.filesUrl),function(file,index){return _c('SlValidate',{key:file.id,attrs:{"vid":file.id,"rules":{
        'required': !_vm.isSourceFile,
        'sl_url': {
          message: file.error
        }
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
      var validate = ref.validate;
      var reset = ref.reset;
return [_c('SlUploadLink',{attrs:{"value":file.url,"is-invalid":invalid,"validator":validate,"upload-callback":_vm.uploadFileWithURL},on:{"upload-start":function($event){return _vm.emitUploading(true)},"upload-end":function($event){return _vm.emitUploading(false)},"upload-success":function (value) { return _vm.handleFileUrlUploaded(Object.assign({}, value, {index: index})); },"upload-error":function (error) { return _vm.handleFileUrlUploadError({ error: error, index: index }); },"input":function (value) { return _vm.handleUpdateFileUrl({ value: value, index: index }); },"remove":function (cb) { return _vm.handleRemoveFileUrl({ index: index, reset: reset, cb: cb }); }}})]}}],null,true)})}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltip({
        content: _vm.$t('Web.FileUpload.TooltipMaxFiles', { 1: _vm.MAX_FILES }),
        disabled: _vm.filesUrl.length < _vm.remainFiles
      })),expression:"getTooltip({\n        content: $t('Web.FileUpload.TooltipMaxFiles', { 1: MAX_FILES }),\n        disabled: filesUrl.length < remainFiles\n      })"}],staticClass:"spreadsheet__upload-link-btn"},[_c('SlButton',{attrs:{"variant":"secondary","color":"grey","disabled":_vm.filesUrl.length >= _vm.remainFiles,"inline":""},on:{"click":_vm.handleAddFileUrl},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"size-16",attrs:{"data":require("@icons/plus.svg")}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('Web.FileUpload.AddUrl'))+" ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }