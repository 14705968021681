var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SlModal',{attrs:{"id":_vm.id,"title":_vm.$t('Main.Ui.acImportFromSpire'),"loading":_vm.isLoading,"close-callback":_vm.handleCancel,"persistent":""},on:{"on-enter":_vm.handleConnect,"created":_vm.onCreated,"hide":_vm.onModalHide},scopedSlots:_vm._u([{key:"modals",fn:function(){return [_c('DatasourceConnectionError',{attrs:{"id":_vm.modalsId.CONNECTOR_ERROR}})]},proxy:true},{key:"footer",fn:function(){return [_c('SlModalFooter',[_c('SlButton',{attrs:{"variant":"secondary","color":"grey"},on:{"click":_vm.handleCancel}},[_vm._v(" "+_vm._s(_vm.$t('Common.Cancel'))+" ")]),_c('SlButton',{on:{"click":_vm.handleConnect}},[_vm._v(" "+_vm._s(_vm.$t('Web.DbImport.ButtonConnect'))+" ")])],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"observer",staticClass:"modal-content dynamic-nav-connector"},[_c('div',{staticClass:"dynamic-nav-connector__row"},[_c('SlValidate',{attrs:{"vid":"domain","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.BaseConnectors.Form.Host'),"is-invalid":invalid,"required":""},model:{value:(_vm.domainModel),callback:function ($$v) {_vm.domainModel=$$v},expression:"domainModel"}})]}}])}),_c('SlValidate',{attrs:{"vid":"port","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.BaseConnectors.Form.Port'),"is-invalid":invalid,"required":""},model:{value:(_vm.portModel),callback:function ($$v) {_vm.portModel=$$v},expression:"portModel"}})]}}])})],1),_c('div',{staticClass:"dynamic-nav-connector__row"},[_c('SlValidate',{attrs:{"vid":"login","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlInput',{attrs:{"label":_vm.$t('Web.BaseConnectors.Form.Login'),"is-invalid":invalid,"required":""},model:{value:(_vm.loginModel),callback:function ($$v) {_vm.loginModel=$$v},expression:"loginModel"}})]}}])})],1),_c('div',{staticClass:"dynamic-nav-connector__row"},[_c('SlValidate',{attrs:{"vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlPasswordInput',{attrs:{"label":_vm.$t('Web.BaseConnectors.Form.Password'),"is-invalid":invalid,"required":""},model:{value:(_vm.passwordModel),callback:function ($$v) {_vm.passwordModel=$$v},expression:"passwordModel"}})]}}])})],1),(_vm.showDropzoneFile)?_c('div',{staticClass:"connector-content-row"},[_c('SlDropzonePreviewItem',{attrs:{"file":_vm.dropzoneFile},on:{"remove":_vm.handleRemoveFile}})],1):_c('SlValidate',{attrs:{"vid":"file","rules":"sl_file_type:.pem|sl_file_size"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validate = ref.validate;
return [_c('SlDropzone',{ref:"dropzone",attrs:{"label":_vm.$t('Web.BaseConnectors.Form.TLSCert'),"description":_vm.$t('Web.BaseConnectors.Form.TLSCertDescription'),"accepted":_vm.pemAccepted,"upload-callback":function (ref) {
	var value = ref.value;

	return _vm.uploadFile(value);
},"validator":validate,"is-invalid":invalid},on:{"upload-start":function($event){return _vm.handleToggleUploading(true)},"upload-end":function($event){return _vm.handleToggleUploading(false)},"files-updated":_vm.handleFilesUpdated},scopedSlots:_vm._u([{key:"accepted",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Web.File.PEMFormat'))+" ")]},proxy:true}],null,true)})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }