<template>
  <SlModal
    :id="id"
    :title="title"
    :persistent="isUploading"
    :close-callback="handleCancel"
    @on-enter="handleSave"
    @created="onCreated"
    @hidden="onModalHide"
  >
    <div class="modal-content spreadsheet-connector">
      <SlAlert
        type="info"
        :accent="alertAccent"
        :text="$t('Web.Spreadsheet.UpdateDataAlertText')"
      />
      <ValidationObserver
        ref="dropzoneObserver"
      >
        <SlValidate
          v-slot="{ invalid, validate }"
          vid="file"
          rules="required|sl_file_type:.xlsx,.xls,.csv|sl_file_size"
        >
          <SlDropzone
            ref="dropzone"
            :accepted="spreadsheetAccepted"
            :max-files="maxFiles"
            :tooltip-max-files="maxFiles"
            :upload-callback="uploadFile"
            :validator="validate"
            :is-invalid="invalid"
            @queue-upload-start="toggleUploading(true)"
            @queue-upload-end="toggleUploading(false)"
            @upload-success="handleFileUploaded"
            @file-removed="handleRemoveFile"
          >
            <template #accepted>
              <div>
                {{ $t('Web.FileUpload.SupportedFormat', { 1: $t('Web.Spreadsheet.UploadAccepted') }) }}
              </div>
              <div>
                {{ $t('Web.FileUpload.MaxFileSize', { 1: '500MB' }) }}
              </div>
            </template>
          </SlDropzone>
        </SlValidate>
      </ValidationObserver>
    </div>

    <template #footer>
      <SlModalFooter between>
        <SlLink
          color="primary"
          @click="handleDownloadLastFile"
        >
          <template #prepend>
            <icon
              data="@icons/download.svg"
              class="fill-off size-20 color-primary-80"
            />
          </template>
          {{ $t('Web.Spreadsheet.BtnDownloadPreviousDataSource') }}
        </SlLink>
        <div class="sl-modal__footer-group">
          <SlButton
            variant="secondary"
            color="grey"
            @click="handleCancel"
          >
            {{ $t('Common.Cancel') }}
          </SlButton>
          <SlButton
            :disabled="isUploading"
            @click="handleSave"
          >
            {{ $t('Web.Modals.BtnSave') }}
          </SlButton>
        </div>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import { fileSaver } from '@/mixins/webAPI';
import FileUploadAdapter from '@/adapters/response/connection/spreadsheet/file/FileUpload.adapter';
import UpdateDataAdapter from '@/adapters/request/connection/spreadsheet/UpdateData.adapter';
import { spreadsheetAccepted } from '@/config/utils/fileUpload.config';
import { connectionTypes } from '@/config/connection';
import { MAX_FILES } from '@/config/connection/spreadsheet.config';

export default {
  name: 'SpreadsheetUpdateDataModal',
  mixins: [modal, fileSaver],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      spreadsheetAccepted,
      files: [],
      isUploading: false,
      reimportType: null,
      reimportCallback: null
    };
  },
  computed: {
    ...mapState({
      filesSource: state => state.spreadsheet.files_source,
      maxFiles: state => +state.spreadsheet.project_info?.filesCount || MAX_FILES
    }),
    ...mapGetters({
      connectorType: 'project/connectorType'
    }),
    title() {
      return this.reimportType === 'Full'
        ? this.$t('Main.ReloadFully')
        : this.$t('Main.Ui.acReloadLatestPeriods');
    },
    connectionDataType() {
      return this.connectorType === connectionTypes.SPREADSHEET_TRANSACTIONAL
        ? this.$t('Main.Ui.acOrderListImport')
        : this.$t('Main.Ui.acSheetImport');
    },
    alertAccent() {
      return this.$t('Web.Spreadsheet.UpdateDataAlertTitle', {
        1: this.$tc('Web.Pluralization.File', 9, { 1: 9 }),
        2: this.connectionDataType
      });
    }
  },
  methods: {
    ...mapActions({
      uploadFile: 'spreadsheet/uploadFile',
      downloadFile: 'spreadsheet/downloadFile'
    }),
    onModalHide() {
      this.files = [];
      this.isUploading = false;
      this.reimportType = null;
      this.reimportCallback = null;
    },
    handleCancel() {
      this.hideModal(this.id);
    },
    toggleUploading(value) {
      this.isUploading = value;
    },
    handleFileUploaded({ file, response }) {
      this.files.push(FileUploadAdapter(response?.data, file.upload.uuid));
    },
    handleRemoveFile(file) {
      this.files = this.files.filter(item => item.id !== file.upload.uuid);
    },
    async validate() {
      const dropzoneObserverValid = await this.$refs.dropzoneObserver.validate();
      const dropzoneValid = this.$refs.dropzone.validate();

      return dropzoneObserverValid && dropzoneValid;
    },
    async handleSave() {
      const valid = await this.validate();

      if (!valid) {
        return;
      }

      this.reimportCallback(UpdateDataAdapter(this.files));

      this.hideModal(this.id);
    },
    handleDownloadLastFile() {
      this.saveFile(
        this.downloadFile.bind(this),
        {
          notifyType: 'notify',
          errorTitle: this.$t('Web.Spreadsheet.NotifyDownloadErrorTitle'),
          errorText: this.$t('Web.Spreadsheet.NotifyDownloadErrorText')
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/connection/connectors/spreadsheet/spreadsheet-connector-modal.scss";
</style>
