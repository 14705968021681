<template>
  <SidebarDropdown v-bind="$attrs">
    <template
      v-for="(_, slot) in $scopedSlots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope || {}"
      />
    </template>
    <template #content="{ close: closeParentDropdown }">
      <div class="dropdown-menu">
        <div
          v-if="needLicenseDetails"
          class="dropdown-menu__block dropdown-menu__block--license-details"
        >
          <div class="dropdown-menu__block-remain">
            <div class="dropdown-menu__block-loader">
              <SlProgressLoader
                :done-pint="remainDonePoint"
                :progress-point="remainProgressPoint"
                :progress-unit="remainProgressUnit"
                size="md"
                :color="remainColor"
              />
            </div>
            <div
              class="dropdown-menu__block-remain-text body-3 grey-80"
            >
              {{ remainText }}
            </div>
          </div>
          <div class="dropdown-menu__block-details">
            <div class="dropdown-menu__block-title body-3-sb grey-80">
              {{ subscriptionExpiredDetails }}
            </div>
            <div class="dropdown-menu__block-description body-3 grey-70">
              {{ $t('Web.LicenseDetails.ContactAdminToMaintain') }}
            </div>
          </div>
        </div>
        <div class="dropdown-menu__block dropdown-menu__block--info">
          <div class="dropdown-menu__title">
            {{ $sl_userName }}
          </div>
          <div class="dropdown-menu__description">
            {{ userRole }}
          </div>
        </div>
        <div class="dropdown-menu__block">
          <SidebarDropdownButton
            :label="$t('Web.Sidebar.Preferences')"
            icon="preferences"
            @click="showModal(modalsId.LANGUAGE_UI)"
          />
          <SidebarDropdownButton
            :label="$t('Web.Sidebar.AdminPortal')"
            icon="admin_portal"
            @click="$sl_showAdminPortal"
          />
          <SidebarDropdownButton
            v-if="$sl_isUserAdmin"
            :label="$t('Web.LicenseDetails.Subscription')"
            icon="card"
            :href="{
              name: routeNames.SUBSCRIPTION
            }"
          />
        </div>
        <div class="dropdown-menu__block">
          <!-- <SidebarDropdownButton
            :label="$t('Main.Ui.btStartPageTutorials')"
            icon="tutorials"
            :href="`/${routeNames.TUTORIALS}`"
          /> -->
          <SidebarDropdownButton
            :label="$t('Main.Ui.btStartPageExamples')"
            icon="examples"
            :href="{
              name: routeNames.EXAMPLES
            }"
          />
          <SidebarDropdownButton
            :label="$t('Main.Ui.helpButton')"
            icon="guide"
            :href="GUIDE_URL"
            target="_blank"
          />
          <SidebarDropdownButton
            :label="$t('Web.Sidebar.KeyboardShortcuts')"
            icon="keyboard_shortcuts"
            @click="showModal(modalsId.KEYBOARD_SHORTCUTS)"
          />
          <SidebarDropdownButton
            :label="$t('Web.Sidebar.SystemDetails')"
            icon="system_version"
            @click="showModal(modalsId.SYSTEM_DETAILS)"
          />
          <SidebarDropdown>
            <template #target>
              <SidebarDropdownButton
                :label="'Contact support'"
                icon="chat"
              >
                <template #append>
                  <icon
                    data="@icons/chevron_right.svg"
                    class="fill-off size-16"
                  />
                </template>
              </SidebarDropdownButton>
            </template>
            <template #content>
              <div class="dropdown-menu">
                <SidebarDropdownButton
                  label="Email support"
                  icon="chat"
                  @click="handleOpenChat(closeParentDropdown)"
                />
                <SidebarDropdownButton
                  :label="$t('Web.ReportIssue.Title')"
                  icon="report_issue"
                  @click="handleOpenReportIssue(closeParentDropdown)"
                />
              </div>
            </template>
          </SidebarDropdown>
        </div>
        <div class="dropdown-menu__block">
          <SidebarDropdownButton
            :label="$t('Web.Sidebar.Logout')"
            icon="logout"
            @click="logout({ withRedirect: true })"
          />
        </div>
      </div>
    </template>
  </SidebarDropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SidebarDropdown from '@/components/SidebarMenu/Dropdown/SidebarDropdown.vue';
import SidebarDropdownButton from '@/components/SidebarMenu/Dropdown/SidebarDropdownButton.vue';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import { routeNames } from '@/config/router/router.config';
import { GUIDE_URL } from '@/config/shared/resources.config';
import modalsId from '@/config/shared/modalsId.config';
import { LICENSE_EXPIRE_START_DAYS } from '@/config/license';

export default {
  name: 'UserDropdown',
  components: {
    SidebarDropdown,
    SidebarDropdownButton
  },
  mixins: [modal, access],
  data() {
    return {
      modalsId,
      routeNames,
      GUIDE_URL
    };
  },
  computed: {
    ...mapGetters({
      userRole: 'user/userRole',
      gracePeriodDays: 'initialization/gracePeriodDays',
      expiresIn: 'initialization/expiresIn',
      currentGraceDay: 'initialization/currentGraceDay',
      isExpired: 'initialization/isExpired',
      expirationLocaleDate: 'initialization/expirationLocaleDate',
      needLicenseDetails: 'initialization/needLicenseDetails',
      expireDetails: 'initialization/expireDetails'
    }),
    remainColor() {
      return this.isExpired ? 'red' : 'yellow';
    },
    remainDonePoint() {
      return this.isExpired ? this.gracePeriodDays : LICENSE_EXPIRE_START_DAYS;
    },
    remainProgressPoint() {
      return this.isExpired ? this.currentGraceDay : this.expiresIn;
    },
    remainProgressUnit() {
      return this.$t('Web.LicenseDetails.InfoProcessDays.val', {
        p1: this.$tc('Web.LicenseDetails.InfoProcessDays.p1', this.remainProgressPoint, { n: this.remainProgressPoint })
      });
    },
    remainText() {
      return this.isExpired
        ? this.$t('Web.LicenseDetails.PastDue')
        : this.$t('Web.LicenseDetails.Remain');
    },
    subscriptionExpiredDetails() {
      return this.isExpired
        ? this.$t('Web.LicenseDetails.SubscriptionExpired', { 1: this.expirationLocaleDate })
        : (this.$t('Web.LicenseDetails.SubscriptionExpiresIn.val', {
          p1: this.$tc('Web.LicenseDetails.SubscriptionExpiresIn.p1', this.expiresIn, { n: this.expiresIn })
        }));
    }
  },
  methods: {
    ...mapActions({
      logout: 'user/logout'
    }),
    handleOpenChat(close) {
      this.showModal(modalsId.REPORT_ISSUE);

      close();
    },
    handleOpenReportIssue(close) {
      this.showModal(modalsId.REPORT_ISSUE, {
        needLogs: true
      });

      close();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/sidebar-menu/dropdown-menu.scss";
</style>
