<template>
  <div class="lang-switcher">
    <SlSelect
      :value="langLabelModel"
      :options="langItems"
      :max-height="115"
      track-by="value"
      label="text"
      auto-size
      @input="handleLangChange"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { $cwd_filterLocales } from '@/plugins/localization/crowdin';

export default {
  name: 'LangSwitcher',
  data() {
    return {
      langLabelModel: ''
    };
  },
  computed: {
    ...mapState({
      language: state => state.settings.language
    }),
    availableLocales() {
      return this.$root.$i18n.availableLocales.filter($cwd_filterLocales());
    },
    langItems() {
      return this.availableLocales.map(locale => {
        return {
          text: this.getLocaleKey(locale),
          value: locale
        };
      });
    }
  },
  created() {
    this.langLabelModel = this.language;
  },
  methods: {
    getLocaleKey(lang) {
      const current = require(`/public/locale/${lang}/locale.json`);
      const internationalLocale = current?.locale?.international;
      const nativeLocale = current?.locale?.native;

      if (internationalLocale !== nativeLocale) {
        return `${internationalLocale} / ${nativeLocale}`;
      }

      return internationalLocale;
    },
    handleLangChange(code) {
      this.langLabelModel = code;
      this.$emit('change-lang', code);
    }
  }
};
</script>
