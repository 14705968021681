<template>
  <main>
    <SidebarMenu />

    <SlErrorBoundary v-if="$sl_hasPlanningItemsPageAccess">
      <template v-if="$slots.page">
        <slot name="page" />
      </template>
      <router-view
        v-else
        v-slot="{ Component }"
        :key="$route.name"
      >
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </SlErrorBoundary>
    <SlNoData v-else>
      <template #image>
        <icon
          data="@icons/illustration/no_planning_items.svg"
          class="illustration-md"
        />
      </template>
      <template #title>
        <div class="w-400">
<!--    todo add locale with crowdin      -->
          {{ 'No entities available due to catalog access permissions.' }}
        </div>
      </template>
    </SlNoData>

    <NotifyModal :id="modalsId.NOTIFY" />
  </main>
</template>

<script>
import SidebarMenu from '@/components/SidebarMenu/Index.vue';
import NotifyModal from '@/components/Modals/Shared/NotifyModal.vue';
import MetaInfoIssuesModal from '@/components/Modals/Warnings/MetaInfoIssuesModal.vue';
import { access } from '@/mixins/access';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'Main',
  components: {
    SidebarMenu,
    NotifyModal,
    MetaInfoIssuesModal
  },
  mixins: [access],
  data() {
    return {
      modalsId
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_mixins.scss";

main {
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
}
</style>
